var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgot-password-container column" }, [
    _c("div", [_c("p", [_vm._v(_vm._s(this.$t("forgotPasswordHeaderText")))])]),
    _c("div", { staticClass: "credentials column" }, [
      _c(
        "label",
        {
          class: [{ visibility: _vm.emailaddress == "" }],
          attrs: { for: "emailaddress" }
        },
        [_vm._v("\n            " + _vm._s(_vm.$t("emailLabelText")))]
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailaddress,
            expression: "emailaddress"
          }
        ],
        class: { "invalid-field": !_vm.isEmailAddressValid },
        attrs: {
          type: "email",
          name: "emailaddress",
          placeholder: _vm.$t("emailLabelText")
        },
        domProps: { value: _vm.emailaddress },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.emailaddress = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "sign-buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn round primary",
          class: [{ disabled: !_vm.isEmailAddressValid }],
          on: {
            click: function($event) {
              _vm.forgotPassword()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("next")))]
      ),
      _c(
        "button",
        {
          staticClass: "btn round secondary",
          on: {
            click: function($event) {
              _vm.signIn()
            }
          }
        },
        [_vm._v("\n           " + _vm._s(_vm.$t("back")) + " ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }