var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgot-password-container column" }, [
    _c("div", [_c("p", [_vm._v(_vm._s(this.$t("resetPasswordHeaderText")))])]),
    _c("div", { staticClass: "credentials column" }, [
      _c(
        "label",
        {
          class: [{ visibility: _vm.password == "" }],
          attrs: { for: "password" }
        },
        [_vm._v("\n            " + _vm._s(_vm.$t("signPasswordLabelText")))]
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password"
          }
        ],
        class: { "invalid-field": !_vm.isPasswordValid },
        attrs: {
          type: "password",
          name: "password",
          placeholder: _vm.$t("signPasswordLabelText")
        },
        domProps: { value: _vm.password },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.password = $event.target.value
          }
        }
      }),
      _c(
        "label",
        {
          class: [{ visibility: _vm.password == "" }],
          attrs: { for: "passwordConfirmation" }
        },
        [
          _vm._v(
            "\n            " + _vm._s(_vm.$t("passwordConfirmationLabelText"))
          )
        ]
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.passwordConfirmation,
            expression: "passwordConfirmation"
          }
        ],
        class: { "invalid-field": !_vm.passwordConfirmationMatches },
        attrs: {
          type: "password",
          name: "passwordConfirmation",
          placeholder: _vm.$t("passwordConfirmationLabelText")
        },
        domProps: { value: _vm.passwordConfirmation },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.passwordConfirmation = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "sign-buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn round primary",
          class: [
            {
              disabled: !_vm.passwordConfirmationMatches || !_vm.isPasswordValid
            }
          ],
          on: {
            click: function($event) {
              _vm.updatePassword()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("next")))]
      ),
      _c(
        "button",
        {
          staticClass: "btn round secondary",
          on: {
            click: function($event) {
              _vm.signIn()
            }
          }
        },
        [_vm._v("\n           " + _vm._s(_vm.$t("back")) + " ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }