<template>
<div class="authentication-section">
    <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="left-section">
                    <!-- <h1>Welcome to</h1> -->
                    <img :src="star"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 right-section-container">
                <div class="right-section"
                    :class="[{'storing-signup-container': currentSection == 'sign-up'}]">
                    <component :is="currentSection"/>
                </div>
            </div>
        </div>
    </div>
<!-- <div class="authentication-section ">
    <div class="authentication-container ">
        <div class="left-section">
            <h1>Welcome to</h1>
             <img  :src="star"/>
        </div>
        <div class="right-section"
            :class="[{'storing-signup-container': currentSection == 'sign-up'}]">
            <component :is="currentSection"/>
        </div>
    </div>
</div> -->
</div>
</template>

<script>
import SignIn from "@/components/Authentication/SignIn.vue";
import ForgotPassword from "@/components/Authentication/ForgotPassword.vue";
import UpdatePassword from "@/components/Authentication/UpdatePassword.vue";

export default{
    name: "Authentication",
    components: {
        "sign-in": SignIn,
        "forgot-password": ForgotPassword,
        "update-password": UpdatePassword,
    },
    computed: {
        currentSection(){
            return this.$store.state.authenticationForm
        }, 
        star(){
             return require('@/assets/icons/StoryOfAmerica.svg')
        },
        mapImage(){
            return require('@/assets/Texas Map asset.png')
        }
    },
    created(){
        if(this.$route.meta.form){
            this.$store.commit('updateAuthenticationForm', this.$route.meta.form)
        }
    }
}
</script>

<style lang="less" scoped>
.right-section-container{
    align-self: center;
}
.authentication-section{
    width: 100%;
    // height: auto;
    height: calc(100vh - 80px);
    background-color: #0A1554;
    margin-top: 80px;
    .left-section{
        padding:65px 0 0 149px;
        position: relative;
        img {
            position: absolute ;
            width: 476px;
            margin-top: 13px;
            @media (min-width: 992px) and (max-width: 1199.98px) {
                width: 100%;
            }
        }
        h1 {
            color: #FFFFFF;
            font-size: 45px;
            font-weight: 600;
            padding-left: 30px;
        }
        
    }
    //4k Devices (desktops, 2560px and up)
    @media (min-width: 2000px) and (max-width: 3080px){
        .left-section{
            display: flex;
            justify-content: center;
            padding:65px 0 0 50px;
            img {
                width: 100%;
            }
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) and (max-width: 1199.98px) {
        .left-section{
            padding:65px 0 0 50px;
            img {
                width: 100%;
            }
        }
    }
    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-width: 991.98px) { 
        .left-section{
            padding:65px 0 0 15px;
            margin-left: 12px;
            margin-top: 0px;
            img {
                width: 100%;

            }
        }
    }
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) { 
        .left-section{
            padding:65px 0 0 5px;
            img {
                width: 100%;
            }
            h1 {
                font-size: 35px;
            }
        }
    }
    @media (max-width: 575.98px) { 
        .left-section{
            padding:15px 0 0 0;
            img {
                display: none;
                width: -webkit-fill-available;
                height: auto;
            }
            h1 {
                font-size: 35px;
                text-align: center;
                padding: 0 0 0 0;
            }
        }
    }
    .right-section{
        margin-top: 240px !important;
        margin-left: 76px;
        width: 416px;
        border-radius: 8px;
        background-color: #FFFFFF;
        height: 232px;
        margin-top: 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        // margin-right: 46px;
        .signin-container {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            padding-top: 0;
            padding-bottom: 0;
        }
        .forgot-password-container {
            width: 100%;
            height: 100%;
            .sign-buttons {
                width: 100%;
            }
        }
    }
    //4k Devices (desktops, 2560px and up)
    @media (min-width: 2000px) and (max-width: 3080px){
        .right-section{
            margin-left: 0;
            margin-left: 208px;
            width: 680px;
            height: 368px;
            // margin-top: 32px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
        .right-section{
            margin-left: 0;
            margin-left: 32px;
            // margin-top: 32px;
        }
    }
    @media (min-width: 768px) and (max-width: 991.98px) { 
        .right-section{
            margin-left: 0;
            width: 100%;
            height: 270px;
        }
    }
    @media (min-width: 576px) and (max-width: 767.98px) { 
        .right-section{
            margin-left: 0;
            width: 100%;
            height: 270px;
            .btn {
                width: 100% !important;
            }
            .signin-container {
                .sign-buttons {
                    button {
                        width: 100% !important;
                    }   
                }
            }
        }
    }
    @media (max-width: 575.98px) { 
        .right-section{
            margin: 0;
            width: 100%;
            top: 0;
            height: 270px;
            margin-top: 160px !important;
            transform: translateY(-34%);
        }
    }

    .storing-signup-container{
        height: 456px;
        margin-top: 120px;
    }
    .authentication-container{
        min-width: 1024px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: auto;

        .star-image{
            position: absolute;
            margin-top: 336px;
            margin-left: 349px;
            z-index: 1;
            height: 50px;
            width: 50px;

            @media screen and (max-width:1141px) {
                margin-left: 425px;
            }
        }

        .map-image{
            position: absolute;
            margin-top: 84px;
            margin-left: -100px;
            z-index: 1;
            height: 262px;
            width: 250px;

            @media screen and (max-width:1141px) {
                margin-left: -20px;
            }
        }

        
        // .left-section{
        //     width: 350px;
        //     min-width: 350px;
        //     margin-bottom: 100px;
        //     margin-top: 50px;
        //     margin-left: 100px;
           
        //     @media screen and (max-width:1141px) {
        //         margin-left: 200px;
        //         width: 280px;
        //         min-width: 200px;
        //     }

        //     :first-child{
        //         color: #FFFFFF;
        //         font-size: 45px;
        //         font-weight: 600;
        //     }
        //     :nth-child(2){
        //        height: 45vh;
            
        //        object-fit: cover;
              
        //     }
        //     :last-child{
        //         color: #ff4c4c;
        //         font-size: 45px;
        //         margin-top: -10px;
        //         letter-spacing: 2px;
        //         font-weight: 600;
        //         margin-left: -30px;
        //     }
        // }

    }
}
</style>
