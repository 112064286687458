var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "authentication-section" }, [
    _c("div", { staticClass: "container-fluid h-100" }, [
      _c("div", { staticClass: "row h-100" }, [
        _c("div", { staticClass: "col-lg-6 col-md-6 col-sm-6" }, [
          _c("div", { staticClass: "left-section" }, [
            _c("img", { attrs: { src: _vm.star } })
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-lg-6 col-md-6 col-sm-6 right-section-container" },
          [
            _c(
              "div",
              {
                staticClass: "right-section",
                class: [
                  {
                    "storing-signup-container": _vm.currentSection == "sign-up"
                  }
                ]
              },
              [_c(_vm.currentSection, { tag: "component" })],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }