var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signin-container column" }, [
    _c("div"),
    _c(
      "div",
      {
        staticClass: "sign-credentials column",
        on: {
          keyup: function($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.signIn($event)
          }
        }
      },
      [
        _c(
          "label",
          {
            class: [{ visibility: _vm.username == "" }],
            attrs: { for: "username" }
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("userNameLabelText")))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.username,
              expression: "username"
            }
          ],
          attrs: {
            type: "text",
            name: "username",
            placeholder: _vm.$t("userNameLabelText")
          },
          domProps: { value: _vm.username },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.username = $event.target.value
            }
          }
        }),
        _c(
          "label",
          {
            class: [{ visibility: _vm.password == "" }],
            attrs: { for: "password" }
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("signPasswordLabelText")))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          attrs: {
            type: "password",
            name: "password",
            placeholder: _vm.$t("signPasswordLabelText")
          },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        })
      ]
    ),
    _c("div", { staticClass: "sign-buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn round primary",
          class: [{ disabled: !_vm.isCredentialsValid }],
          on: {
            click: function($event) {
              _vm.signIn()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("signLoginButtonText")))]
      ),
      _c(
        "button",
        {
          staticClass: "btn round secondary",
          on: {
            click: function($event) {
              _vm.forgotPassword()
            }
          }
        },
        [_vm._v("\n           " + _vm._s(_vm.$t("signForgotButtonText")) + " ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }