<template>
    <div class="forgot-password-container column">
        <div>
            <p>{{this.$t("forgotPasswordHeaderText")}}</p>
        </div>
        <div class="credentials column">
            <label for="emailaddress" :class="[{visibility: emailaddress == ''}]">
                {{$t("emailLabelText")}}</label>
            <input type="email" name="emailaddress" v-model="emailaddress" :class="{'invalid-field': !isEmailAddressValid}"
                    :placeholder='$t("emailLabelText")'/>
        </div>
        <div class="sign-buttons">
            <button class="btn round primary" :class="[{'disabled': !isEmailAddressValid}]"
                    @click="forgotPassword()">{{$t('next')}}</button>
            <button class="btn round secondary" @click="signIn()">
               {{$t('back')}} </button>
        </div>
    </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService.js"
import ValidationHelper from '@/utils/ValidationHelper.js'
export default{
    name: "ForgotPassword",
    data: function(){
        return { 
            emailaddress: ""
        };
    },
    computed: {
        isEmailAddressValid(){
            return this.emailaddress != ''
                ? ValidationHelper.validateEmail(this.emailaddress)
                : true
        }
    },
    methods: {
        forgotPassword(){
            AuthenticationService.forgotPassword(this.emailaddress)
                .then((response)=> {this.$store.commit('addSnackBarNotification',{
                    message: response.data.message})
                    this.$store.commit('updateAuthenticationForm', 'sign-in')
                })
                .catch(({response:{status, data}})=>{
                    if(status === 400)
                        this.$store.commit('addSnackBarNotification', {message: data.message})
                })
        }, 
        signIn(){
            this.$store.commit('updateAuthenticationForm', 'sign-in')
        }
    }
}
</script>
<style lang="less" scoped>
.forgot-password-container{
    width: 329px;
    height: 302px;
    padding: 46px 43px 50px 44px;

    p{
        margin: 0;

        width: 261px;
        color: rgba(0,0,0,0.77);
        font-family: Montserrat;
        font-size: 24px;
        line-height: 32px;
    }

    .credentials{
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;

        label{
            height: 14px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 6px;
        }

        label.visibility{
            visibility: hidden;
        }

        input{
            width: 100%;
            border: none;
            border-bottom: 1px solid #9E9E9E;

            height: 24px;
            min-height: 24px;
            color: rgba(0,0,0,0.54);
            font-family: Roboto;
            font-size: 16px;
            line-height: 24px;

            margin: 0;
            padding-bottom: 3px;

            &:focus{
                outline: none;
            }
        }

        input:first-of-type {
            margin-bottom: 23px;
        }
    }

    .sign-buttons{
        width: 100%;
        margin-bottom: 0;


        button:first-child{
            height: 40px; 
            @media (max-width: 991.98px) {  
                width: 100%;
                margin-bottom: 10px;
            }
        }

        button:nth-child(2){
            // margin-left: 22px;
            height: 42px;
            float: right;
             @media (max-width: 991.98px) {  
                width: 100%;
            }
        }

        button.disabled{
            background-color: rgba(158, 158, 158, 0.801);
        }
    }
}
</style>
