<template>
    <div class="signin-container column">
        <div>
            <!-- <p>{{this.$t("signInHeaderText")}}</p> -->
        </div>
        <div class="sign-credentials column" v-on:keyup.enter="signIn">
            <label for="username" :class="[{visibility: username == ''}]">
                {{$t("userNameLabelText")}}</label>
            <input type="text" name="username" v-model="username" 
                    :placeholder='$t("userNameLabelText")'/>

            <label for="password" :class="[{visibility: password == ''}]">
                {{$t("signPasswordLabelText")}}</label>
            <input type="password" name="password" v-model="password" 
                    :placeholder='$t("signPasswordLabelText")'/>
        </div>
        <div class="sign-buttons">
            <button class="btn round primary" :class="[{'disabled': !isCredentialsValid}]"
                    @click="signIn()">{{$t('signLoginButtonText')}}</button>
            <button class="btn round secondary" @click="forgotPassword()">
               {{$t('signForgotButtonText')}} </button>
        </div>
    </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService.js";
import NotificationsService from "@/services/NotificationsService.js";
import { Role } from '@/utils/Roles.js'

export default{
    name: "SignIn",
    data: function(){
        return { 
            username: "", 
            password: ""
        };
    },
    computed: {
        isCredentialsValid(){
            return this.username != "" && this.password != "";
        }
    },
    methods: {
        signIn(){
            if(!this.isCredentialsValid)
                return;
            AuthenticationService.signIn({ username: this.username, password: this.password })
            .then((response) => {
                this.$store.commit('updateLanguage', response.data.textLanguage)
                this.setUpNotificationService(response);
                this.redirectUser()
            }, error => {
                this.$store.commit('addSnackBarNotification', {
                        message: error, 
                        dismissable: false
                    });
            });
        },
        forgotPassword(){
            this.$store.commit('updateAuthenticationForm', 'forgot-password')
        }, 
        redirectUser(){
            let user = AuthenticationService.getProfile()
            let routeName = Role[user.roleName.replace(' ', '')].home
            this.$route.query.returnUrl
                ? this.$router.push({ path: this.$route.query.returnUrl})
                : this.$router.push({ name: routeName})
        },
        setUpNotificationService(response){
            NotificationsService.setNotificationsHandler({
                    messageType: "ReceiveMessage",
                    handler: () => this.$store.dispatch('loadNotifications')
                });

            if(response.data.roleId == Role.ContentCreator.roleId){
                let _failedMessage = this.$t("FailedToPublishMessage");
                let _publishingMessage = this.$t("publishingMessage");
                let _sucessfullPublishingMessage = this.$t("sucessfullPublishingMessage");

                NotificationsService.setNotificationsHandler({
                        messageType: "Publishing",
                        handler: message => {
                                    if(message.isPublishing){
                                        if(message.publisherId != response.data.id){
                                            this.$store.commit('addSnackBarNotification', {
                                                message: _publishingMessage, 
                                                dismissable: false
                                            });

                                            if(this.$route.name === "UnitDashboardBuilder")
                                                this.$store.commit('loadUnitDashboardComponents', []);
                                            else
                                                this.$router.push({ name: "UnitDashboardBuilder" });
                                        }
                                    }
                                    else if(message.hasError){
                                        this.$store.dispatch("loadUnitDashboardComponents");
                                        this.$store.commit('addSnackBarNotification', {
                                            message: _failedMessage, 
                                            dismissable: false
                                        });
                                    }
                                    else{
                                        this.$store.commit("sortUnitDashboardComponents");
                                        this.$store.commit('addSnackBarNotification', {
                                            message: _sucessfullPublishingMessage,
                                            dismissable: false
                                        });
                                    }
                                }
                    });

                NotificationsService.setNotificationsHandler({
                        messageType: "UnitPublished",
                        handler: message => {
                                    if(this.$route.name === "UnitDashboardBuilder")
                                        this.$store.commit("createUnitDashboardComponent", message.unit);
                                }
                    });
            }

            NotificationsService.startNotificationsHubConnection();
            this.$store.dispatch('loadNotifications');
        }
    }
}
</script>
<style lang="less" scoped>
.signin-container{
    width: 100%;
    // width: 329px;
    // height: 250px;
    padding: 46px 43px 50px 44px;

    p{
        margin: 0;
        width: 261px;
        color: rgba(0,0,0,0.77);
        font-family: Montserrat;
        font-size: 24px;
        line-height: 32px;
    }

    .sign-credentials{
        width: 100%;
        margin-top: auto;
        margin-bottom: 24px;

        label{
            height: 14px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 6px;
        }

        label.visibility{
            visibility: hidden;
        }

        input{
            width: 100%;
            border: none;
            border-bottom: 1px solid #9E9E9E;

            height: 24px;
            min-height: 24px;
            color: rgba(0,0,0,0.54);
            font-family: Roboto;
            font-size: 16px;
            line-height: 24px;

            margin: 0;
            padding-bottom: 3px;

            &:focus{
                outline: none;
            }
        }

        input:first-of-type {
            margin-bottom: 23px;
        }
    }

    .sign-buttons{
        width: 100%;
        margin-bottom: 0;


        button:first-child{
            height: 40px;
            width: 108px;
            @media (max-width: 991.98px) {  
                width: 100%;
                margin-bottom: 10px;
            }
        }

        button:nth-child(2){
            // margin-left: 22px;
            height: 42px;
            width: 199px;
            float: right;
            @media (max-width: 991.98px) {  
                width: 100%;
                float: none;
            }
        }
        button.disabled{
            background-color: rgba(158, 158, 158, 0.801);
        }
    }
}
</style>
